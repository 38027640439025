import { render, staticRenderFns } from "./LoyaltyAdvantages.vue?vue&type=template&id=6b8d2a58&"
import script from "./LoyaltyAdvantages.vue?vue&type=script&lang=js&"
export * from "./LoyaltyAdvantages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports