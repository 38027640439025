<template>
  <div class="world loyalty-upgrade" v-if="dealer">
    <div class="wrapper-title">
      <div class="container">
        <router-link :to="{name: 'ww.dashboard'}" class="text-wilmsLightBlue font-100 pb-4 block">&larr; {{ $t('Back to overview') }}</router-link>

        <div class="loyalty-date">{{ date }}</div>
        <div class="loyalty-title">{{ $t('Welkom terug,') }} {{ currentUser.profile.first_name }}</div>
        <div v-if="dealer" class="loyalty-chosen-dealer">{{ $t('Gekozen dealer:') + dealer.name }}</div>
      </div>
    </div>

    <div v-if="dealer.loyalty" class="wrapper-menu">
      <div class="container">
        <ul class="loyalty-menu">
          <li>
            <router-link :to="{name: 'ww.loyalty', params: {id: dealer.id}}">{{ $t('Status') }}</router-link>
          </li>
          <li class="active">
            <router-link :to="{name: 'ww.loyalty.advantages', params: {id: dealer.id}}">{{ $t('Voordelen') }}</router-link>
          </li>
          <li>
            <router-link :to="{name: 'ww.loyalty.upgrade', params: {id: dealer.id}}">{{ $t('Upgraden') }}</router-link>
          </li>
        </ul>

        <a class="blueButton btn-sm" download href="/Wilms_Salespresentatie_DEF_NL-gecomprimeerd.pdf">
          <div>
            <img width="16" src="@/assets/img/world/loyalty/download.png" alt="alert">{{ $t('Download sterrenprogramma') }}
          </div>
        </a>
      </div>
    </div>

    <div class="wrapper-status" v-if="dealer.loyalty">
      <div class="container">
        <div class="col-status" :class="dealer.loyalty.stars !== 4 ? 'col-current-status' : ''">
          <div class="status">
            <h3>{{ $t('Jouw status') }}</h3>
            <Stars :stars="dealer.loyalty.stars"/>
          </div>
          <div class="period">
            <h3>{{ $t('Total score') }}</h3>
            <p>{{ dealer.loyalty.total }}/100</p>
          </div>
        </div>

        <div class="col-status col-upgrade-status" v-if="dealer.loyalty.stars !== 4">
          <div class="status">
            <h3>{{ $t('Upgrade naar') }}</h3>
            <Stars :stars="dealer.loyalty.next_stars"/>
          </div>
          <div class="period">
            <h3>{{ $t('Total score') }}</h3>
            <p>{{ dealer.loyalty.total + dealer.loyalty.points_to_earn }} /100</p>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper-advantages">
      <div class="container">
        <div class="col-title">
          <h4>{{ $t('Advantages') }}</h4>
        </div>
        <div class="advantages">
          <div :key="loyaltyAdvantage.id" class="advantage" :class="'rating-' + loyaltyAdvantage.stars" v-for="loyaltyAdvantage in loyaltyAdvantagesByStars">
            <div class="percentage">{{ loyaltyAdvantage.amount }}%</div>
            <div class="content">
              <h5>{{ getTranslation(loyaltyAdvantage).title }}</h5>
              <div v-html="getTranslation(loyaltyAdvantage).body"></div>
            </div>
          </div>

          <div :key="loyaltyAdvantage.id" class="advantage general" v-for="loyaltyAdvantage in loyaltyAdvantagesGeneral">
            <div class="percentage">{{ loyaltyAdvantage.amount }}%</div>
            <div class="content">
              <h5>{{ getTranslation(loyaltyAdvantage).title }}</h5>
              <div v-html="getTranslation(loyaltyAdvantage).body"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Stars from '@/components/world/Stars'
import { ApiService } from '@/services/admin/api.service'

export default {
  components: {
    Stars
  },
  data () {
    return {
      loyaltyAdvantagesByStars: [],
      loyaltyAdvantagesGeneral: []
    }
  },
  async mounted () {
    await this.setDealer()

    if (!this.hasLoyaltyAccess) {
      await this.$router.push({ name: 'ww.dashboard' })
    }

    const response = await ApiService.fetchLoyaltyAdvantagesByRating(this.dealer.loyalty.stars)

    if (response.data) {
      this.loyaltyAdvantagesByStars = response.data.filter((loyaltyAdvantage) => parseInt(loyaltyAdvantage.stars) === parseInt(this.dealer.loyalty.stars))
      this.loyaltyAdvantagesGeneral = response.data.filter((loyaltyAdvantage) => parseInt(loyaltyAdvantage.stars) === 0)
    }

    this.title = this.dealer.name
  },
  computed: {
    date () {
      const date = moment().locale(this.activeLocale).format('dddd, DD MMMM YYYY')
      return date.charAt(0).toUpperCase() + date.slice(1)
    }
  }
}
</script>
